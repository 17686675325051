import React, { useMemo, useState, useEffect } from 'react';

const Basket = ({ items, setBasket, onNavigateHome, colorNameFromHex, totalPrice }) => {
  const [phoneCases, setPhoneCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shippingOption, setShippingOption] = useState(null);
  const [email, setEmail] = useState('');
  const [purchaseDisabled, setPurchaseDisabled] = useState(false);
  const [initialCountryDetected, setInitialCountryDetected] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState({
    name: '',
    line1: '',
    line2: '',
    postalOrZipCode: '',
    townOrCity: '',
    stateOrCounty: '',
    countryCode: '', // Will be set based on browser location
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const detectCountry = () => {
      const defaultCountry = 'GB';
      const detectedCountry = navigator.language.includes('US') ? 'US' :
                              navigator.language.includes('CA') ? 'CA' : defaultCountry;
      setDeliveryAddress(prevAddress => ({ ...prevAddress, countryCode: detectedCountry }));
    };

    detectCountry();
  }, []);

  useEffect(() => {
    const fetchPhoneCases = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/phone-cases`);
        console.log('Response:', response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched phone cases:', data);
        if (!Array.isArray(data)) {
          console.error('Fetched data is not an array:', data);
        } else {
          setPhoneCases(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching phone cases:', error);
        setIsLoading(false);
      }
    };

    fetchPhoneCases();
  }, [deliveryAddress.countryCode]);

const getBrands = () => {
  const brands = [...new Set(phoneCases.map(phoneCase => phoneCase.brand))];
  console.log('Brands:', brands);
  return brands;
};

const getStylesByBrand = (brand) => {
  const styles = [...new Set(phoneCases
    .filter(phoneCase => phoneCase.brand === brand)
    .map(phoneCase => phoneCase.style))];
  console.log(`Styles for brand ${brand}:`, styles);
  return styles;
};

const getFinishesByStyle = (brand, style) => {
  const finishes = [...new Set(phoneCases
    .filter(phoneCase => phoneCase.brand === brand && phoneCase.style === style)
    .map(phoneCase => phoneCase.finish))];
  console.log(`Finishes for brand ${brand} and style ${style}:`, finishes);
  return finishes;
};

const getModelsByFinish = (brand, style, finish) => {
  const models = [...new Set(phoneCases
    .filter(phoneCase => phoneCase.brand === brand && phoneCase.style === style && phoneCase.finish === finish)
    .map(phoneCase => {
      const descriptionParts = phoneCase.productDescription ? phoneCase.productDescription.split(' ') : [];
      return descriptionParts[1] || '';
    }))].sort();
  console.log(`Models for brand ${brand}, style ${style}, and finish ${finish}:`, models);
  return models;
};

const getDescriptionsByFinish = (brand, model, finish) => {
  const descriptions = [...new Set(phoneCases
    .filter(phoneCase => phoneCase.brand === brand && phoneCase.productDescription && phoneCase.productDescription.includes(model) && phoneCase.finish === finish)
    .map(phoneCase => phoneCase.productDescription))].sort();
  console.log(`Descriptions for brand ${brand}, model ${model}, and finish ${finish}:`, descriptions);
  return descriptions;
};

  const calculateTotalCost = (items, shippingOption) => {
    let total = items.reduce((total, item) => {
      let itemTotal = 2.00; // Base price of wallpaper
      if (item.includePhoneCase) {
        const phoneCase = phoneCases.find(phoneCase => phoneCase.brand === item.phoneCaseBrand && phoneCase.productDescription.includes(item.phoneCaseModel) && phoneCase.finish === item.phoneCaseFinish && phoneCase.style === item.phoneCaseStyle);
        if (phoneCase) {  // Ensure phoneCase is defined
          const casePrice = phoneCase.price;
          itemTotal += (casePrice * 1.2 + 5.10) * item.quantity; // Adding VAT and fixed cost
        } else {
          console.error(`Phone case not found for brand: ${item.phoneCaseBrand}, model: ${item.phoneCaseModel}`);
        }
      }
      return total + itemTotal;
    }, 0);

    // Add shipping cost
    if (shippingOption && items.some(item => item.includePhoneCase)) {
      const { baseCost, additionalCost } = shippingOption;
      const phoneCaseItems = items.filter(item => item.includePhoneCase);
      if (phoneCaseItems.length > 0) {
        total += baseCost;
        if (phoneCaseItems.length > 1) {
          total += additionalCost * (phoneCaseItems.length - 1);
        }
      }
    }

    return total;
  };

  const shippingOptions = [
    { id: 'budget', label: 'Budget Shipping (£2.10)', baseCost: 2.10, additionalCost: 0.50 },
    { id: 'express', label: 'Express Shipping (£5.50)', baseCost: 5.50, additionalCost: 1.00 },
    { id: 'overnight', label: 'Overnight Shipping (£9.95)', baseCost: 9.95, additionalCost: 2.00 },
  ];

  const handleRemoveItem = (url) => {
    setBasket(prevBasket => prevBasket.filter(item => item.url !== url));
  };

  const handleCaseQuantityChange = (index, quantity) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, quantity } : item
    );
    setBasket(updatedItems);
  };

  const handlePurchase = async () => {
    if (purchaseDisabled) return; // Prevent duplicate submissions
    const errors = {};
    const requiresAddress = items.some(item => item.includePhoneCase);
    const requiresEmail = requiresAddress;

    if (requiresEmail && !email) errors.email = 'Email is required';
    if (requiresAddress && !deliveryAddress.line1) errors.line1 = 'Address Line 1 is required';
    if (requiresAddress && !deliveryAddress.townOrCity) errors.townOrCity = 'Town or City is required';
    if (requiresAddress && !deliveryAddress.postalOrZipCode) errors.postalOrZipCode = 'Postal or Zip Code is required';
    if (requiresAddress && !shippingOption) errors.shippingOption = 'Shipping option is required';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setPurchaseDisabled(true); // Disable the button to prevent multiple submissions
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: items.map(item => ({
            ...item,
	sku: item.sku,
            price: calculateTotalCost([item], null),
            quantity: item.quantity || 1
          })),
          shippingAddress: requiresAddress ? deliveryAddress : null,
          shippingOption: requiresAddress ? shippingOption : null,
          email: requiresEmail ? email : null,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { url } = await response.json();

      if (url) {
        console.log("Redirecting to Stripe Checkout URL:", url);
        window.location.href = url;
      } else {
        console.error('Stripe session URL is not defined');
      }
    } catch (error) {
      console.error('Failed to initiate purchase:', error);
    } finally {
      setPurchaseDisabled(false); // Re-enable the button after submission
    }
  };

  const totalCost = useMemo(() => calculateTotalCost(items, shippingOption), [items, shippingOption]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!Array.isArray(phoneCases)) {
    return <div>Error loading phone cases.</div>;
  }

  return (
    <div className="basket container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Your Basket</h2>
      {items.length === 0 ? (
        <div>
          <p>Your basket is empty.</p>
          <button onClick={onNavigateHome} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
            Home
          </button>
        </div>
      ) : (
        <div>
          <div className="flex justify-between mb-4          ">
            <button onClick={onNavigateHome} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Continue Shopping
            </button>
            <div className="flex items-center">
              <p className="text-xl font-bold mr-4">Total: £{totalCost.toFixed(2)}</p>
              <button
                onClick={handlePurchase}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                disabled={purchaseDisabled}
              >
                Purchase
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {items.map((item, index) => (
              <div key={index} className="basket-item p-4 border rounded shadow">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}${item.url}`}
                  alt={`Wallpaper ${index}`}
                  className="w-full h-32 object-cover mb-2 rounded"
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click
                />
                <div>
                  <p className="font-bold">{item.description}</p>
                  <p>Color: {colorNameFromHex(item.color)}</p>
                  <p>Price: £2.00</p>
                  <button onClick={() => handleRemoveItem(item.url)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 mt-2 rounded">
                    Remove
                  </button>
                  <div className="mt-2">
                    <label>
                      <input
                        type="checkbox"
                        checked={item.includePhoneCase || false}
                        onChange={() => {
                          const updatedItems = items.map((basketItem, i) =>
                            i === index ? { ...basketItem, includePhoneCase: !basketItem.includePhoneCase, quantity: 1 } : basketItem
                          );
                          setBasket(updatedItems);
                        }}
                      />
                      {' '}Matching Phone Case (from £14.50)
                    </label>
                    {item.includePhoneCase && (
                      <>


			    <select
  value={item.phoneCaseBrand || ''}
  onChange={(e) => {
    const selectedBrand = e.target.value;
    const updatedItems = items.map((basketItem, i) => {
      if (i === index) {
        const selectedPhoneCase = phoneCases.find(phoneCase =>
          phoneCase.brand === selectedBrand &&
          phoneCase.style === item.phoneCaseStyle &&
          phoneCase.finish === item.phoneCaseFinish &&
          phoneCase.productDescription === item.phoneCaseDescription
        );
        return {
          ...basketItem,
          phoneCaseBrand: selectedBrand,
          phoneCaseStyle: '',
          phoneCaseFinish: '',
          phoneCaseDescription: '',
          sku: selectedPhoneCase ? selectedPhoneCase.sku : ''  // Set the SKU here
        };
      }
      return basketItem;
    });
    setBasket(updatedItems);
  }}
  className="ml-2 p-1 border rounded"
>
  <option value="">Select Brand</option>
  {getBrands().map((brand, i) => (
    <option key={i} value={brand}>
      {brand}
    </option>
  ))}
</select>



<select
  value={item.phoneCaseStyle || ''}
  onChange={(e) => {
    const updatedItems = items.map((basketItem, i) =>
      i === index ? { ...basketItem, phoneCaseStyle: e.target.value, phoneCaseFinish: '', phoneCaseModel: '', phoneCaseDescription: '' } : basketItem
    );
    setBasket(updatedItems);
  }}
  className="ml-2 p-1 border rounded"
>
  <option value="">Select Style</option>
  {getStylesByBrand(item.phoneCaseBrand).map((style, i) => (
    <option key={i} value={style}>
      {style}
    </option>
  ))}
</select>

<select
  value={item.phoneCaseFinish || ''}
  onChange={(e) => {
    const updatedItems = items.map((basketItem, i) =>
      i === index ? { ...basketItem, phoneCaseFinish: e.target.value, phoneCaseModel: '', phoneCaseDescription: '' } : basketItem
    );
    setBasket(updatedItems);
  }}
  className="ml-2 p-1 border rounded"
>
  <option value="">Select Finish</option>
  {getFinishesByStyle(item.phoneCaseBrand, item.phoneCaseStyle).map((finish, i) => (
    <option key={i} value={finish}>
      {finish}
    </option>
  ))}
</select>

<select
  value={item.phoneCaseDescription || ''}
  onChange={(e) => {
    const updatedItems = items.map((basketItem, i) =>
      i === index ? { ...basketItem, phoneCaseDescription: e.target.value } : basketItem
    );
    setBasket(updatedItems);
  }}
  className="ml-2 p-1 border rounded">
  <option value="">Select Product Description</option>
  {getDescriptionsByFinish(item.phoneCaseBrand, item.phoneCaseStyle, item.phoneCaseFinish).map((description, i) => (
    <option key={i} value={description}>
      {description}
    </option>
  ))}
</select>

                        <select
                          value={item.quantity || 1}
                          onChange={(e) => handleCaseQuantityChange(index, parseInt(e.target.value, 10))}
                          className="ml-2 p-1 border rounded"
                        >
                          {Array.from({ length: 10 }, (_, i) => i + 1).map((qty) => (
                            <option key={qty} value={qty}>{qty}</option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {items.some(item => item.includePhoneCase) && (
            <div className="mt-4">
              <p>We only use your personal address details for shipping purposes only and do not store them.</p>
              <label className="block mb-2">Email Address:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-2 border rounded w-full"
                required
              />
              {formErrors.email && <p className="text-red-500">{formErrors.email}</p>}
              <label className="block mb-2 mt-4">Delivery Address:</label>
              <input
                type="text"
                placeholder="Name"
                value={deliveryAddress.name}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, name: e.target.value })}
                className="p-2 border rounded w-full mb-2"
              />
              <input
                type="text"
                placeholder="Address Line 1"
                value={deliveryAddress.line1}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, line1: e.target.value })}
                className="p-2 border rounded w-full mb-2"
                required
              />
              {formErrors.line1 && <p className="text-red-500">{formErrors.line1}</p>}
              <input
                type="text"
                placeholder="Address Line 2"
                value={deliveryAddress.line2}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, line2: e.target.value })}
                className="p-2 border rounded w-full mb-2"
              />
              <input
                type="text"
                placeholder="Postal or Zip Code"
                value={deliveryAddress.postalOrZipCode}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, postalOrZipCode: e.target.value })}
                className="p-2 border rounded w-full mb-2"
                required
              />
              {formErrors.postalOrZipCode && <p className="text-red-500">{formErrors.postalOrZipCode}</p>}
              <input
                type="text"
                placeholder="Town or City"
                value={deliveryAddress.townOrCity}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, townOrCity: e.target.value })}
                className="p-2 border rounded w-full mb-2"
                required
              />
              {formErrors.townOrCity && <p className="text-red-500">{formErrors.townOrCity}</p>}
              <input
                type="text"
                placeholder="State or County"
                value={deliveryAddress.stateOrCounty}
                onChange={(e) => setDeliveryAddress({ ...deliveryAddress, stateOrCounty: e.target.value })}
                className="p-2 border rounded w-full mb-2"
              />
              <label className="block mb-2 mt-4">Country</label>
              <select
                value={deliveryAddress.countryCode}
                onChange={(e) => {
                  setDeliveryAddress({ ...deliveryAddress, countryCode: e.target.value });
                  setInitialCountryDetected(false);
                }}
                className="p-2 border rounded w-full mb-2"
                required
              >
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
              </select>
              {formErrors.countryCode && <p className="text-red-500">{formErrors.countryCode}</p>}
              <label className="block mb-2 mt-4">Shipping Options:</label>
              {shippingOptions.map(option => (
                <div key={option.id} className="mb-2">
                  <input
                    type="radio"
                    id={option.id}
                    name="shippingOption"
                    value={option.id}
                    checked={shippingOption && shippingOption.id === option.id}
                    onChange={() => setShippingOption(option)}
                  />
                  <label                     htmlFor={option.id} className="ml-2">{option.label}</label>
                </div>
              ))}
              {formErrors.shippingOption && <p className="text-red-500">{formErrors.shippingOption}</p>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Basket;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Wallpaper = React.memo(({ wallpaper, onAddToBasket, hasGenerated }) => {
  const [isAdded, setIsAdded] = useState(false);
  const [backgroundUrl, setBackgroundUrl] = useState(null);

  // Use environment variable for the server URL
  const serverUrl = process.env.REACT_APP_API_BASE_URL; // Replace with your server's IP address

  // Static array of old wallpapers
  const oldWallpapers = [
    "wallpapers/old/wallpaper1.jpg",
    "wallpapers/old/wallpaper2.jpg",
    "wallpapers/old/wallpaper3.jpg",
    "wallpapers/old/wallpaper4.jpg",
    "wallpapers/old/wallpaper5.jpg",
    "wallpapers/old/wallpaper6.jpg",
    "wallpapers/old/wallpaper7.jpg",
    "wallpapers/old/wallpaper8.jpg",
    "wallpapers/old/wallpaper9.jpg",
    "wallpapers/old/wallpaper10.jpg",
    "wallpapers/old/wallpaper11.jpg",
    "wallpapers/old/wallpaper12.jpg",
    "wallpapers/old/wallpaper13.jpg",
    "wallpapers/old/wallpaper14.jpg"
    // Add more filenames as needed
  ];

  useEffect(() => {
    // Set a random wallpaper from the oldWallpapers array on initial load
    const randomWallpaper = oldWallpapers[Math.floor(Math.random() * oldWallpapers.length)];
    console.log('Initial random wallpaper:', randomWallpaper);
    setBackgroundUrl(`${serverUrl}/${randomWallpaper}`);
  }, [serverUrl]); // Run only once on initial load

  useEffect(() => {
    if (wallpaper) {
      const fullUrl = `${serverUrl}${wallpaper}`;
      console.log('Setting background URL:', fullUrl);
      setBackgroundUrl(fullUrl);
    }
    setIsAdded(false); // Reset 'isAdded' state when a new wallpaper is generated
  }, [wallpaper, serverUrl]);

  const handleAddToBasket = () => {
    onAddToBasket();
    setIsAdded(true);
  };

  console.log('Wallpaper URL:', backgroundUrl);

  return (
    <div className="wallpaper" style={{
      backgroundImage: `url(${backgroundUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh'
    }}>
      <div className="overlay flex flex-col items-center justify-center w-full h-screen">
        {!hasGenerated && (
          <div className="intro-text bg-white p-4 rounded shadow-lg text-center mb-4">
            <p>Transform your devices with stunning, one-of-a-kind wallpapers inspired by the greatest artists in history. Then, order a phone case to match! Exclusive art, wrap-around designs, just for you. <Link to="/how-it-works" className="text-blue-500 hover:text-blue-700">How it works.</Link></p>
          </div>
        )}
        {hasGenerated && (
          <>
            <button onClick={handleAddToBasket} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out" disabled={isAdded}>
              {isAdded ? 'Added' : 'Add to Basket'}
            </button>
          </>
        )}
      </div>
    </div>
  );
});

export default Wallpaper;

import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

function DownloadPage() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [filePaths, setFilePaths] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionId) {
      // Fetch download links
      fetch(`${process.env.REACT_APP_API_BASE_URL}/download-session?session_id=${sessionId}`)
        .then(async response => {
          console.log('Download session response:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const json = await response.json();
          console.log('Download links JSON:', json); // Debugging log
          setFilePaths(json.filePaths || []);
        })
        .catch(error => console.error('Error fetching download link:', error));

      // Fetch order details if they exist
      fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch-prodigi-order?session_id=${sessionId}`)
        .then(async response => {
          console.log('Prodigi order response:', response);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const json = await response.json();
          console.log('Prodigi order JSON:', json); // Debugging log
          const orderData = {
            orderNumber: json.order.id,
            orderItems: json.items || [],
            shippingOption: json.shippingOption || {},
          };
          setOrderDetails(orderData);
        })
        .catch(error => console.error('Error fetching Prodigi order summary:', error));
    }
  }, [sessionId]);

  const handleContinueShopping = () => {
    navigate('/');
  };

  return (
    <div className="download-page container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Thank You for Your Purchase!</h2>
      <p>Loading your download link...</p>
      {filePaths.length > 0 && (
        <div>
          <p>Your download links (opens in a new tab):</p>
          {filePaths.map((path, index) => (
            <div key={index}>
              <a href={`${process.env.REACT_APP_API_BASE_URL}${path}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                Download Wallpaper {index + 1}
              </a>
            </div>
          ))}
        </div>
      )}
      {orderDetails && (
        <div className="mt-4">
          <h3 className="text-lg font-bold mb-2">Order Summary</h3>
	      <p>Time to get excited! Your order is on its way!</p>
          <p>Order Number: {orderDetails.orderNumber}</p>
          <div className="p-2 border rounded">
            <h4 className="font-bold mb-2">Ordered Items:</h4>
            {orderDetails.orderItems.length > 0 ? (
              <ul>
                {orderDetails.orderItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    <p><strong>Item:</strong> {item.description || 'Custom Phone Case'}</p>
                    <p><strong>Quantity:</strong> {item.quantity}</p>
                    <p><strong>Price:</strong> £{item.price.toFixed(2)}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No items found in the order.</p>
            )}
          </div>
          {orderDetails.shippingOption.label && (
            <p><strong>Shipping Option:</strong> {orderDetails.shippingOption.label}</p>
          )}
          {orderDetails.orderItems.some(item => item.includePhoneCase) && (
            <p>Check your email for a separate notification about the phone case delivery.</p>
          )}
        </div>
      )}
      <button onClick={handleContinueShopping} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
        Continue Shopping
      </button>
    </div>
  );
}

export default DownloadPage;
